import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import eachserviceStyles from "../styles/eachservice.module.scss"
import Layout from "../components/layout"


const EndOfTenancyPage = ((props) => {
  return (
    <Layout>
      <div className={eachserviceStyles.maindiv}>
        <div className={eachserviceStyles.headerdiv}>End of Tenancy Cleaning Service</div>
        <div className={eachserviceStyles.imagediv}>
          <Img className={eachserviceStyles.imagetag} fluid={props.data.endofTenacyCleaning.childImageSharp.fluid} alt="domesticCleaning"/>
        </div>
        <div className={eachserviceStyles.textdiv}>
            Whatever your position, cleaning is a leading cause of distress and deposit dispute between landlords and tenants at the end of a tenant contract. 
            When it comes to move out, many tenants neglect to do a final End of Tenancy Cleaning. 
            The main reason is that they think it will be too difficult or will cost too much. 
            Cleaning a whole apartment or house by yourself can be difficult and most people opt not to do it. 
            Fortunately, we are more than happy to help you out with our professional end of tenancy cleaning services. 
            You don't need to worry about getting your deposit in full from your landlord or paying too expensive prices for cleaning anymore.
        </div>
      </div>
    </Layout>
  )
}
)

export default EndOfTenancyPage

export const pageQuery = graphql`
  query {
    endofTenacyCleaning: file(relativePath: {eq: "EndofTenacyCleaning.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`